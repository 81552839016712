import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, switchMap, first } from 'rxjs/operators';

import { AppConfigService } from '../services/app-config.service';

/**
 * Interceptor to add access token to requests.
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	private readonly appConfigService = inject(AppConfigService);

	/** Request to refresh token. */
	private refreshTokenRequest$: Observable<string> | null = null;

	/**
	 * Refreshes a token.
	 * @inheritdoc
	 */
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			catchError((error: unknown) => {
				if (!(error instanceof HttpErrorResponse)) {
					throw error;
				}
				if (error.status !== 401) {
					throw error;
				}
				if (this.shouldRefreshToken(req.url)) {
					throw error;
				}
				this.refreshTokenRequest$ = this.refreshTokenRequest$ ?? this.requestRefreshToken();
				return this.refreshTokenRequest$.pipe(
					switchMap(token =>

					// TODO (template preparation): Add new token to current request.
						this.updateToken(token).pipe(
							first(),
							switchMap(() => next.handle(req)),
						)),
				);
			}),
		);
	}

	private updateToken(token: string): Observable<void> {
		// TODO (template preparation)
		throw new Error(`Not implemented${token}`);
	}

	/**
	 * Checks if a request is for authorization or refresh token.
	 * @param url - Request url.
	 */
	private shouldRefreshToken(url: string): boolean {
		return (
			url.startsWith(this.appConfigService.apiUrl) &&
      !url.startsWith(`${this.appConfigService.apiUrl}/auth`)
		);
	}

	private requestRefreshToken(): Observable<string> {
		// TODO (template preparation)
		throw new Error('Not implemented');
	}
}
